<template>
  <div class="tw-max-w-9xl tw-mx-auto sm:tw-px-8 lg:tw-px-16 tw-px-3">
    <div class="tw-flex tw-justify-between">
      <h1 class="tw-block tw-text-black tw-text-xl tw-leading-relaxed tw-font-semibold tw-mb-4">{{ titleMapping[$route.name] }}</h1>
      <router-link :to="{ name: 'legal' }" class="tw-text-red tw-text-sm tw-flex tw-items-center tw-mb-4">
        <svg class="tw-mr-3" width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5 1L1 5L5 9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        {{$t('legal.back_to_legal')}}
      </router-link>
    </div>
    <router-view></router-view>
  </div>
</template>


<script>
import i18n from '@/i18n'

export default {
  data() {
    return {
      titleMapping: {
        'terms.of.use': i18n.t('legal.terms'),
        'privacy.policy': i18n.t('legal.privacy'),
      }
    }
  }
}
</script>
